<template>
  <div>
    <onboarding
      @click:open-info="isFnrhInfoModalOpen = true"
      @click:letsgo="$store.dispatch('flow/reservation/next')"
    />
    <fnrh-info-modal
      :opened="isFnrhInfoModalOpen"
      @click:close="isFnrhInfoModalOpen = false"
    ></fnrh-info-modal>
  </div>
</template>

<script>
import { ref } from "vue"

import FnrhInfoModal from "./components/FnrhInfoModal.vue"
import Onboarding from "./components/Onboarding.vue"

export default {
  components: {
    Onboarding,
    FnrhInfoModal,
  },
  setup() {
    const isFnrhInfoModalOpen = ref(false)
    return {
      isFnrhInfoModalOpen,
    }
  },
}
</script>
