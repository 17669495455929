<template>
  <z-card data-cy="onboarding" class="card-container">
    <template #content>
      <section class="main-content-onboarding">
        <div class="checklist-img">
          <img
            v-if="$vuetify.breakpoint.smAndDown"
            style="width: 50%"
            :src="theme.logo.internal"
          />

          <illustration-onboarding
            class="my-1"
            style="width: 70%"
          ></illustration-onboarding>
        </div>

        <div class="text-content">
          <h6 class="body-text text-center text-onboarding" style="">
            {{ $t("onboarding.title") }}
          </h6>
          <p class="body2 text-center my-3 px-4">
            {{ $t("onboarding.subtitle") }}
          </p>
          <ul v-for="list in listItems" :key="list.text">
            <li class="d-flex align-center mb-2">
              <z-icon class="pass-alert-icon mx-2" size="18px" color="green">
                $check_in_a_circle
              </z-icon>
              <p class="mb-0 body2">{{ list.text }}</p>
            </li>
          </ul>
        </div>
        <div class="btn-container">
          <z-btn
            class="letsgo-btn my-4"
            primary
            width="100%"
            :text="$t('onboarding.letsgo_btn')"
            @click="$emit('click:letsgo')"
          />
          <z-btn
            class="fnrh-btn"
            width="100%"
            :text="$t('onboarding.fnrh_btn')"
            @click="$emit('click:open-info')"
          />
        </div>
      </section>
    </template>
  </z-card>
</template>

<script>
import { computed } from "vue"
import { useNamespacedState } from "vuex-composition-helpers/dist"

import i18n from "@/i18n"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { theme } = useNamespacedState("layout", ["theme"])

    const listItems = computed(() => {
      return [
        { text: i18n.tc("onboarding.text_list_1") },
        { text: i18n.tc("onboarding.text_list_2") },
        { text: i18n.tc("onboarding.text_list_3") },
        // { text: i18n.tc("onboarding.text_list_4") },
      ]
    })

    return {
      listItems,
      theme,
    }
  },
}
</script>

<style lang="scss" scoped>
.checklist-img {
  text-align: center;
}

.main-content-onboarding {
  height: 100% !important;
}
.card-container {
  width: 100% !important;
}

.text-onboarding {
  font-size: 18px;
  white-space: pre-wrap;
  word-break: keep-all;
  margin: 0 auto;
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
}

@media screen and (max-width: 380px) {
  .text-onboarding {
    max-width: 250px;
  }
}

@media screen and (max-height: 667px) {
  .card-container {
    width: 98% !important;
    height: 100% !important;
  }
  .main-content-onboarding {
    height: 81vh;
  }
}
</style>
